<template>
    <div>
      <base-page-title :titleText="pageTitle" />
      <b-row class="no-gutters p-3">
        <b-button class="ml-auto" @click="createService">
          {{ $t("button.addService") }}
        </b-button>
      </b-row>
      <b-col class="col-12 col-md-8 p-3 mx-auto">
        <service-predict-list />
      </b-col>
    </div>
  </template>
  
  <script>
  import ServicePredictList from "../components/ServicesPredict/ServicePredictList.vue";
  export default {
    components: {
      ServicePredictList
    },
    data() {
      return {
        pageTitle: "page.services.title"
      };
    },
    methods: {
      createService() {
        this.$router.push({ name: "Service-Create" });
      }
    }
  };
  </script>
  
  <style scoped></style>
  