<template>
  <div>
    <div v-for="service in serviceList" :key="service.id">
      <template>
        <b-card no-body class="p-3 mb-2">
          <b-row class="no-gutters align-items-center">
            <b-col class="col-auto mr-3">
              <img :src="clientTypeImg" class="user__icon" />
            </b-col>
            <b-col>
              <div class="font-weight-bold">
                {{ service.name }}
              </div>
            </b-col>
						<b-col class="">{{ $t("page.services.numAnalysis") }} {{ service.numAnalysis!==0? service.numAnalysis:"Unlimited" }}</b-col>
            <b-col class="col-auto">
              <b-button
                class="mb-1"
                size="sm"
                variant="outline-primary"
								@click="viewDetails(service._id)"
              >
                {{ $t("button.details") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </div>
    <div v-if="isEmptyList">
      <h5 class="text-center">Empty list</h5>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    serviceList() {
      return this.$store.getters["servicePredict/getServiceList"];
    },
    isEmptyList() {
      return this.serviceList.length === 0;
    },
    clientTypeImg() {
      if (this.isClientMultiuser) {
        return require("./../../assets/icons/users.svg");
      } else {
        return require("./../../assets/icons/user.svg");
      }
    },
  },
	methods:{
		viewDetails(serviceId){
			this.$router.push({name:'Service-Details',params:{id:serviceId}})
		}
	}
};
</script>

<style scoped>
.user__icon {
  width: 20px;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(173deg)
    brightness(87%) contrast(113%);
}
</style>
